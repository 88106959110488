/* Using CSS layers here to resolve conflicts between Tailwind, Design System & Mantine styles */
@layer reset, tailwind-base, mantine;

@import '@youversion/design-system-core/dist/styles/global.css';
@import '@youversion/design-system-core/dist/styles/reset.css' layer(reset);
@import '@mantine/core/styles.layer.css';
@import '@mantine/dates/styles.layer.css';

@layer tailwind-base {
  @tailwind base;
}

@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

html,
body,
#__next {
  height: 100%;
  width: 100%;
}

/* This resets some styles that are applied by Mantine, which conflict with Tailwind & YV Design System styles */
body {
  /* Mantine sets this to var(--mantine-text-color), which overrides the color set by the design system on the html element  */
  color: inherit;

  /* Mantine sets this to var(--mantine-line-height), which override the default line height set by Tailwind */
  line-height: inherit;
}

/* to be removed in https://lifechurch.atlassian.net/browse/YV-45200*/
a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#nprogress .bar {
  padding-top: 1px;
  padding-bottom: 1px;
  background: red !important;
  border: 1px solid red;
}

.mantine-DatePicker-day {
  border: none;
  border-radius: 100%;
}

.mantine-DatePicker-day[data-selected='true'] {
  background-color: #f6efee;
  border: 1px solid #121212;
  color: #121212;
}

html[data-theme='dark'] .mantine-DatePicker-day[data-selected='true'] {
  background-color: #373231;
  border: 1px solid #BFBDBD;
  color: #BFBDBD;
}

.mantine-DatePicker-day[data-in-range='true'] {
  border-radius: 0%;
  background-color: #f6efee;
  color: #121212;
}

html[data-theme='dark'] .mantine-DatePicker-day[data-in-range='true'] {
  border-radius: 0%;
  background-color: #373231;
  color: #BFBDBD;
}

.mantine-DatePicker-day[data-first-in-range='true'], .mantine-DatePicker-day[data-last-in-range='true'] { 
  border-radius: 100%;
  background-color: #f6efee;
  border: 1px solid #121212;
  color: #121212;
}

html[data-theme='dark'] .mantine-DatePicker-day[data-first-in-range='true'], html[data-theme='dark'] .mantine-DatePicker-day[data-last-in-range='true']{ 
  border-radius: 100%;
  background-color: #373231;
  border: 1px solid #BFBDBD;
  color: #BFBDBD;
}

.mantine-DatePicker-monthCell {
  padding: 0;
}

.mantine-DatePicker-monthCell:has(> .mantine-DatePicker-day[data-first-in-range='true']) {
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
  background-color: #f6efee;
}

html[data-theme='dark'] .mantine-DatePicker-monthCell:has(> .mantine-DatePicker-day[data-first-in-range='true']) {
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
  background-color: #373231;
}

.mantine-DatePicker-monthCell:has(> .mantine-DatePicker-day[data-last-in-range='true']) {
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background-color: #f6efee;
}

html[data-theme='dark'] .mantine-DatePicker-monthCell:has(> .mantine-DatePicker-day[data-last-in-range='true']) {
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  background-color: #373231;
}
